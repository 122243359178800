<template>
	<div>
		<template v-if="!userInfo.admin">
			<section class="pg_content a_margin-bottom-40">
				<div class="pg_content_container a_max-width-1000">
					<div class="pg_content_1-1">Je moet admin zijn om deze pagina de bezichtigen.</div>
				</div>
			</section>
		</template>
		<template v-else>
			<!-- <period></period> -->
			<section class="pg_content a_margin-bottom-40">
				<div class="pg_content_container a_max-width-1000">
					<div class="pg_content_1-1">
						<header class="pg_header">
							<div class="pg_header_heading">
								<h2>Groesplessen inplannen</h2>
								<!-- <infoDropdown>Vestibulum id ligula porta felis euismod semper.</infoDropdown> -->
							</div>
							<div class="pg_header_description">
								<p>Selecteer een groepsles om lessen in te plannen</p>
							</div>
						</header>
					</div>
				</div>
			</section>
			<rooster-bar />
			<section class="pg_content">
				<div class="pg_content_container a_max-width-1200">
					<div class="pg_content_1-1">
						<div class="a_text_brand_primary-lighter a_text-align_right a_margin-top-60">
							<p>{{ groepslessen.length }} groepslessen</p>
						</div>
						<vue-scroll-table
							class="pg_table_group-lessons"
							:headers="headers"
							:data="mappedGroepslessen"
							:limits="[10, 20, 30]"
							:has-scroll="true"
							:classes="tableStylingClasses"
							:translations="{ limit: 'per pagina' }"
						>
							<template slot="groepsles" slot-scope="{ data }">
								<router-link exact :to="'/groepsles-planning/detail/' + data.attributes.id">
									{{ data.value }}
								</router-link>
							</template>
						</vue-scroll-table>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import VueScrollTable from "vue-scroll-table";
import { mapActions, mapGetters, mapState } from "vuex";
import RoosterBar from "@/components/parts/RoosterBar.vue";
import { logger } from "@/logger";

export default {
	name: "Groepslessen",
	components: {
		RoosterBar,
		VueScrollTable,
	},
	data() {
		return {
			selectedBadge: "aantal-vrije-les-plekken",
			headers: [
				{
					text: "Groepsles",
					width: 160,
					sortable: true,
				},
				{
					text: "Aantal geplande lessen",
					sortable: true,
				},
				{
					text: "Bezette plaatsen",
					sortable: true,
				},
				{
					text: "Bezettings percentage",
					sortable: true,
				},
				{
					text: "Voltooid lessen",
					sortable: true,
				},
				{
					text: "Coach",
					width: 320,
					sortable: true,
				},
				{
					text: "Bezetting t.o.v. vorige keer",
					sortable: true,
				},
			],
			tableStylingClasses: {
				tableContainer: ["pg_table", "pg_table_vst", "pg_table_lessons"],
				pagination: {
					container: ["pg_pagination"],
					limit: {
						container: ["pg_pagination_filters"],
					},
					links: {
						container: ["pg_pagination_links"],
					},
				},
				scroll: {
					table: ["pg_table"],
				},
				sticky: {
					container: ["pg_table-overlay_vst"],
					table: ["pg_table-overlay"],
				},
				sortButtons: {
					button: [
						"button",
						"v_transparent_body",
						"v_is-icon",
						"v_icon-size-16",
						"a_no-margin",
						"a_no-padding",
					],
				},
			},
		};
	},
	computed: {
		...mapState("auth", ["userInfo"]),
		...mapState("branches", { selectedBranch: "selected" }),
		...mapState("statistieken", ["query"]),
		...mapState("groepsles", ["groepslessen"]),
		...mapGetters("rooster", ["getAmountGroepslessenOfRooster"]),
		...mapGetters("groepsles", ["groepslesPlanningen"]),
		mappedGroepslessen() {
			return this.groepslesPlanningen
				.slice()
				.sort((a, b) => {
					if (a.naam < b.naam) return -1;
					if (a.naam > b.naam) return 1;
					return 0;
				})
				.map(({ naam, planning, id }) => {
					return [
						{
							value: naam,
							attributes: {
								id,
							},
						},
						planning.aantalGepland,
						planning.bezet,
						`${planning.bezettingsPercentage}`,
						planning.aantalVoltooid,
						"",
						`${planning.bezettingVergelijking}`,
					];
				});
		},
	},
	mounted() {
		this.getData();
	},
	methods: {
		...mapActions("statistieken", ["getByPeriod", "getCompareByPeriod"]),
		...mapActions("groepsles", ["getGroepslessen", "getGroepslesPlanning"]),
		async getData() {
			try {
				await this.getGroepslessen();
			} catch (error) {
				logger.error(error);
				return;
			}
			await this.localGetGroepslesPlanningen();
		},
		async localGetGroepslesPlanningen() {
			const doGroepslesPlanningRequest = (groepslesId) => this.getGroepslesPlanning(groepslesId);
			const requests = this.groepslessen.map(({ id }) => doGroepslesPlanningRequest(id));
			try {
				await Promise.all(requests);
			} catch (error) {
				logger.error(error);
			}
		},
	},
};
</script>

<style lang="scss">
.pg_table {
	th {
		position: relative;
		user-select: none;
	}
}
.vst_sort-btn {
	position: absolute !important;
	top: 50%;
	right: 0.6rem;
	transform: translateY(-50%);
	&:active {
		transform: translateY(-50%) translateY(1px);
	}
}
</style>
